import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

/**
 * Adds parallax effect to provided elements
 */
export default class Parallaxer {
  constructor() {
    gsap.registerPlugin(ScrollTrigger)
  }

  registerElement(el: HTMLElement, speed: number = 1, mobileSpeed: number = 1, toOrigin: boolean = false) {
    const firstChild = el.children[0]
    if (!firstChild) return

    const offset = 50 * (window.innerWidth >= 768 ? speed : mobileSpeed)

    gsap.set(firstChild, { yPercent: offset })
    gsap.to(firstChild, {
      yPercent: toOrigin ? 0 : -offset,
      ease: 'none',
      scrollTrigger: {
        trigger: el,
        start: 'top bottom',
        end: toOrigin ? 'center center' : 'bottom top',
        scrub: 1
      }
    })
  }
  unregisterElement(el: HTMLElement) {
    const firstChild = el.children[0]
    if (!firstChild) return

    gsap.killTweensOf(firstChild)
  }
}
