<template>
  <VueFinalModal
    :class="classes"
    content-class="modal__inner"
    overlay-transition="vfm-fade"
    content-transition="vfm-fade"
    :click-to-close="true"
    @click="onContainerClick"
  >
    <!-- @vue-ignore -->
    <template #default="{ close }">
      <h2 v-if="props.title" class="modal__title">{{ props.title }}</h2>
      <div class="modal__content">
        <slot :close="close" />
        <div class="modal__raw-content" v-if="props.rawContent" v-html="props.rawContent" />
        <pre v-if="props.codeContent" v-html="props.codeContent" />
      </div>
      <button class="modal__close" @click.stop="onClose(close)" title="Close">
        <Icon name="close" size="large" />
      </button>
    </template>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { VueFinalModal } from 'vue-final-modal'

const props = defineProps<{
  title?: string
  rawContent?: string
  codeContent?: string
  nopadding?: boolean
  collapsible?: boolean
  size?: 'normal' | 'page' | 'full'
  background?: 'white' | 'grey' | 'blue' | 'transparent'
}>()

const emit = defineEmits<{
  (e: 'close'): void
}>()

const isCollapsed = ref<boolean>(props.collapsible)

const classes = computed(() => [
  'modal',
  props.nopadding ? 'modal--nopadding' : '',
  isCollapsed.value ? 'modal--collpased' : '',
  props.background ? `modal--${props.background}` : '',
  props.size ? `modal--size-${props.size}` : '',
  props.background === 'transparent' ? 'has-inverse-colors' : ''
])

const onContainerClick = () => {
  if (isCollapsed.value) isCollapsed.value = false
}

const onClose = (close: () => void) => {
  emit('close')
  close()
}
</script>

<style lang="scss">
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--page-margin);

  &--nopadding {
    padding: var(--page-margin) 0 !important;
  }

  &--collpased {
    top: calc(100% - _rem(88px)) !important;
    cursor: pointer;
  }

  @include breakpoint(small down) {
    padding: 0;
  }

  &__inner {
    background-color: white;
    padding: var(--page-margin);
    border-radius: var(--box-radius-large);
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: _rem(824px);
    max-height: 100vh;
    transition: transform 300ms ease;

    &.vfm-fade-enter-from {
      transform: scale(0.97);
    }
  }

    &--size-page &__inner {
      max-width: var(--page-width);
      height: 100%;
      @include breakpoint(small down) {
        border-radius: 0;
      }
    }
    &--size-full &__inner {
      max-width: 100%;
      height: 100%;
      @include breakpoint(small down) {
        border-radius: 0;
      }
    }

    &--grey &__inner { background-color: var(--color-dark-8-bg); }
    &--blue &__inner { background-color: var(--color-blue); }
    &--transparent &__inner {
      background-color: transparent;
      box-shadow: none;
      padding: 0;
      &.vfm-fade-enter-from {
        transform: none;
      }
    }

  &__title {
    font-size: 1.5rem;
    margin-bottom: var(--page-margin);
    max-width: calc(100% - _rem(40px));
  }

  &__content {
    flex: 1;
    overflow-y: auto;
    min-height: _rem(64px);
  }

  &__raw-content {
    height: 100%;
  }

  &__close {
    top: calc(var(--page-margin) - 5px);
    right: calc(var(--page-margin) - 5px);
    display: flex;
    position: absolute;
    padding: 0;
    border: none;
    background: none;
    color: var(--color-dark);
    border-radius: _rem(4px);
    transition: background-color 150ms ease;
    cursor: pointer;
    &:hover { background-color: var(--color-dark-8-bg); }
  }
    &--transparent &__close {
      color: var(--color-white);
      top: 0;
      right: 0;
      &:hover { background-color: var(--color-light-16-bg); }
    }
    &--nopadding &__close {
      top: 0;
      right: calc(var(--page-margin) - 5px);
    }

  .vfm__overlay {
    background-color: rgba(0, 0, 0, 0.5);
  }
    &--transparent .vfm__overlay {
      background-color: rgba(0, 0, 0, 0.7);
    }
}
</style>
