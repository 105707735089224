import IntersectDirectivePlugin from 'vue3-intersect-directive'

const options = {
  true: { attr: 'data-animated' },
  observerOptions: {
    rootMargin: '-150px',
  },
  disposeWhen: true
}

export default defineNuxtPlugin(({vueApp}) => {
  process.client
    ? vueApp.use(IntersectDirectivePlugin, options)
    : vueApp.directive('intersect', {} )
})