const handler = (error, instance, info) => {
  if (error.statusCode === 404) return

  console.error(`Caught error (${info}):`, error)

  // Notify Bugsnag
  useBugsnag().notify(error as any, (event) => {
    event.context = info
  })
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('vue:error', handler)
  // nuxtApp.vueApp.config.errorHandler = handler
})
