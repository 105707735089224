import { default as _91_46_46_46slug_93ILszah2VNpMeta } from "/app/pages/[...slug].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as component_45stubprJNxEvsq0Meta } from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.23_gzr632mpdvxr2cvbopjlahvsm4/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubprJNxEvsq0 } from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.23_gzr632mpdvxr2cvbopjlahvsm4/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___cs",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93ILszah2VNpMeta || {},
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    meta: _91_46_46_46slug_93ILszah2VNpMeta || {},
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: "index___cs",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: component_45stubprJNxEvsq0Meta?.name,
    path: "/cs",
    component: component_45stubprJNxEvsq0
  },
  {
    name: component_45stubprJNxEvsq0Meta?.name,
    path: "/house-of-lobkowicz",
    component: component_45stubprJNxEvsq0
  },
  {
    name: component_45stubprJNxEvsq0Meta?.name,
    path: "/en/house-of-lobkowicz",
    component: component_45stubprJNxEvsq0
  },
  {
    name: component_45stubprJNxEvsq0Meta?.name,
    path: "/en/lobkowicz-palace-museum",
    component: component_45stubprJNxEvsq0
  },
  {
    name: component_45stubprJNxEvsq0Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubprJNxEvsq0
  },
  {
    name: component_45stubprJNxEvsq0Meta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubprJNxEvsq0
  },
  {
    name: component_45stubprJNxEvsq0Meta?.name,
    path: "/cs-sitemap.xml",
    component: component_45stubprJNxEvsq0
  },
  {
    name: component_45stubprJNxEvsq0Meta?.name,
    path: "/en-sitemap.xml",
    component: component_45stubprJNxEvsq0
  }
]