<template>
  <NuxtLayout>
    <div class="error-page">
      <template v-if="props.error?.statusCode === 404">
        <Section :title="$t('errors.404.title')">
          <div v-html="$t('errors.404.text')" />
        </Section>
      </template>

      <template v-else>
        <Section :title="$t('errors.500.title')">
          <div v-html="$t('errors.500.text')" />
        </Section>
      </template>
    </div>
  </NuxtLayout>
</template>

<script lang="ts" setup>
import type { NuxtError } from '#app'

const props = defineProps({
  error: Object as () => NuxtError
})
</script>

<style lang="scss">
.error-page {
  padding: _rem(128px) 0 0;
  min-height: _rem(500px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__data {
    padding: _rem(16px);
    margin-top: _rem(24px);
    background-color: var(--color-dark-8-bg);
    border-radius: var(--box-radius);
    overflow-x: auto;
  }
}
</style>