export default defineNuxtPlugin(nuxtApp => {
  Object.values((nuxtApp._gqlState as any).value).forEach((client: any) => {
    if (!client.instance) return

    client.instance.requestConfig.responseMiddleware = response => {
      nuxtApp._gqlLastResponse = response

      if (response.errors?.length || response.response?.errors?.length) {
        console.error('GQL error:', response.errors || response.response.errors)
        nuxtApp.callHook('gql:error' as any, { errors: response.errors || response.response.errors });

        // Notify Bugsnag
        (response.errors || response.response.errors).forEach((err: any) => {
          const errorObject = new Error(err.extensions.debugMessage || err.message) as any
          errorObject.name = 'GraphQLError'

          useBugsnag().notify(errorObject, (event) => {
            event.context = err.path?.join(' -> ')
            // Replace stacktrace with the one from the server if available
            if (err.extensions?.file) {
              event.errors[0].stacktrace = [ { file: err.extensions?.file, lineNumber: err.extensions?.line } ]
            }
          })
        })
      }
    }
  })
})
