import type { ObjectPlugin, DirectiveHook, DirectiveBinding, App } from '@vue/runtime-core'

const check = (el: HTMLElement) => {
  el.scrollWidth > el.clientWidth ? el.classList.add('is-overflown-h') : el.classList.remove('is-overflown-h')
  el.scrollHeight > el.clientHeight ? el.classList.add('is-overflown-v') : el.classList.remove('is-overflown-v')
}

const mounted: DirectiveHook = async (el: HTMLElement, binding: DirectiveBinding) => {
  check(el);
  (binding.dir as any).resizeObserver = new ResizeObserver(() => check(el));
  (binding.dir as any).resizeObserver.observe(el)
}

const beforeUnmount: DirectiveHook = async (el: HTMLElement, binding: DirectiveBinding) => {
  (binding.dir as any).resizeObserver?.disconnect()
  delete (binding.dir as any).resizeObserver
}

const plugin: ObjectPlugin = {
  install: (app: App) => {
    app.directive('check-overflow', { mounted, beforeUnmount })
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(plugin)
})