import type { ObjectPlugin, ObjectDirective, DirectiveHook, DirectiveBinding, App } from '@vue/runtime-core'

const mounted: DirectiveHook = async (el: HTMLElement, binding: DirectiveBinding) => {
  if (!binding.value) return
  el.addEventListener('load', () => {
    const width = (el as any).naturalWidth * binding.value
    el.style.width = `${width}px`
  })
}

const plugin: ObjectPlugin = {
  install: (app: App) => {
    app.directive('resize-img', { mounted })
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(plugin)
})