import revive_payload_client_wcPEFVVyY5 from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.23_gzr632mpdvxr2cvbopjlahvsm4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Ic39abWJFu from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.23_gzr632mpdvxr2cvbopjlahvsm4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_NQ2cMggZDi from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.23_gzr632mpdvxr2cvbopjlahvsm4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_Ims9blOA3u from "/app/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.23.0_vite@5.4.8_@types+node@22.7.4_sass@1.77._z6x3i3s3dwfhujx7wu4b7uqgry/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_U1TplyMB36 from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.23_gzr632mpdvxr2cvbopjlahvsm4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bMllJc4cJC from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.23_gzr632mpdvxr2cvbopjlahvsm4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_F5zTkhinZm from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.23_gzr632mpdvxr2cvbopjlahvsm4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_V7HeuwS57x from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.23_gzr632mpdvxr2cvbopjlahvsm4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_Meve9WZXsz from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.23_gzr632mpdvxr2cvbopjlahvsm4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ISZ0D7jJMf from "/app/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.5_rollup@4.23.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import switch_locale_path_ssr_7MmhU4BNaq from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.4.0_magicast@0.3.5_rollup@4.23.0_vue@3.5.10_typescript@5.6.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_FMwPti35sO from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.4.0_magicast@0.3.5_rollup@4.23.0_vue@3.5.10_typescript@5.6.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_x40wbnoYeh from "/app/node_modules/.pnpm/nuxt-graphql-client@0.2.36_@parcel+watcher@2.4.1_@types+node@22.7.4_graphql-tag@2.12.6_graphq_vgb7sqoms67jv276mjbxtjjqiu/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import error_handler_kEP5FliEXj from "/app/plugins/error-handler.ts";
import gql_middleware_qlCetu0vb4 from "/app/plugins/gql-middleware.ts";
import vue_calendar_9NHpT1VPBH from "/app/plugins/vue-calendar.ts";
import vue_check_overflow_u8IaQfF87r from "/app/plugins/vue-check-overflow.ts";
import vue_final_modal_pML93k5qcp from "/app/plugins/vue-final-modal.ts";
import vue_gtm_yUJQMSAo2j from "/app/plugins/vue-gtm.ts";
import vue_intersect_directive_K9CYa3PpXm from "/app/plugins/vue-intersect-directive.ts";
import vue_parallax_3fsXtZrNyb from "/app/plugins/vue-parallax.ts";
import vue_resize_img_Picanwklg6 from "/app/plugins/vue-resize-img.ts";
export default [
  revive_payload_client_wcPEFVVyY5,
  unhead_Ic39abWJFu,
  router_NQ2cMggZDi,
  _0_siteConfig_Ims9blOA3u,
  payload_client_U1TplyMB36,
  navigation_repaint_client_bMllJc4cJC,
  check_outdated_build_client_F5zTkhinZm,
  chunk_reload_client_V7HeuwS57x,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Meve9WZXsz,
  plugin_ISZ0D7jJMf,
  switch_locale_path_ssr_7MmhU4BNaq,
  i18n_FMwPti35sO,
  plugin_x40wbnoYeh,
  error_handler_kEP5FliEXj,
  gql_middleware_qlCetu0vb4,
  vue_calendar_9NHpT1VPBH,
  vue_check_overflow_u8IaQfF87r,
  vue_final_modal_pML93k5qcp,
  vue_gtm_yUJQMSAo2j,
  vue_intersect_directive_K9CYa3PpXm,
  vue_parallax_3fsXtZrNyb,
  vue_resize_img_Picanwklg6
]