<template>
  <!-- <NuxtRouteAnnouncer /> -->
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>

  <Teleport to="body">
    <ModalsContainer />
  </Teleport>
</template>

<style lang="scss">
@import 'assets/scss/main.scss';
@import 'vue-final-modal/style.css';
</style>

<script setup lang="ts">
import sniffBrowser from './lib/sniff-browser'
import 'virtual:svg-icons-register'
import 'core-js/proposals/array-grouping-v2'
import { ModalsContainer, useModal } from 'vue-final-modal'
import Modal from './components/modals/Modal.vue'

if (window) (window as any).browser = sniffBrowser()

const nuxtApp = useNuxtApp()

if (nuxtApp.$config.public.showGqlErrors) {
  nuxtApp.hook('gql:error' as any, ({ errors }) => {
    const { open } = useModal({
      component: Modal,
      attrs: {
        size: 'full',
        background: 'transparent',
        collapsible: true,
        codeContent: JSON.stringify(errors, null, 2),
        title: 'GraphQL Error'
      }
    })
    open()
  })
}

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} | House of Lobkowicz` : 'House of Lobkowicz'
  }
})
</script>
