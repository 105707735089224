import { createGtm } from '@gtm-support/vue-gtm'

export default defineNuxtPlugin((nuxtApp) => {
  if (nuxtApp.$config.public.appEnv !== 'production') return

  const plugin = createGtm({
    id: 'GTM-KS22B5S',
    defer: false,
    compatibility: false,
    enabled: true,
    debug: true,
    loadScript: true,
    vueRouter: nuxtApp.$router as any,
    trackOnNextTick: false,
  })

  nuxtApp.vueApp.use(plugin)
})