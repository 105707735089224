import type { ObjectPlugin, ObjectDirective, DirectiveHook, DirectiveBinding, App } from '@vue/runtime-core'
import Parallaxer from '~/lib/parallaxer'

interface ObjectDirectiveWithParallaxer extends ObjectDirective {
  parallaxer?: Parallaxer
}

const mounted: DirectiveHook = (el: HTMLElement, binding: DirectiveBinding) => {
  let speed = 1
  let mobileSpeed = 1
  let toOrigin = false

  if (binding.value && typeof binding.value == 'object') {
    speed = binding.value.speed || speed
    mobileSpeed = binding.value.mobileSpeed || mobileSpeed
    toOrigin = binding.value.toOrigin || toOrigin
  }

  (binding.dir as ObjectDirectiveWithParallaxer).parallaxer?.registerElement(el, speed, mobileSpeed, toOrigin)
}

const unmounted: DirectiveHook = (el: HTMLElement, binding: DirectiveBinding) => {
  (binding.dir as ObjectDirectiveWithParallaxer).parallaxer?.unregisterElement(el)
}

const directive: ObjectDirectiveWithParallaxer = {
  mounted,
  unmounted,
  parallaxer: process.client ? new Parallaxer() : undefined,
  getSSRProps: () => ({}) // for nuxt
}

const plugin: ObjectPlugin = {
  install: (app: App) => {
    app.directive('parallax', directive)
  }
}

export default defineNuxtPlugin(({vueApp}) => {
  process.client
  ? vueApp.use(plugin)
  : vueApp.directive('parallax', {} )
})