<template>
  <section :class="classes">
    <div class="section__content">
      <div v-if="props.title || props.subtitle" class="section__header">
        <h2 v-if="props.title" class="section__title">{{ props.title }}</h2>
        <div v-if="props.subtitle" class="section__subtitle" v-html="props.subtitle " />
      </div>
      <slot />
    </div>
  </section>
</template>

<script lang="ts" setup>
const props = defineProps<{
  title?: string | null
  subtitle?: string | null
  fullwidth?: boolean
  narrow?: boolean
  noPadding?: boolean
  noBottomPadding?: boolean
  background?: string | null
  flatHeader?: boolean
}>()

const hasInverseColors = computed( () => [ 'blue', 'dark-blue', 'red' ].includes(props.background || '') )

const classes = computed(() => [
  'section',
  `section--${props.background || 'white'}`,
  props.fullwidth ? 'section--fullwidth' : '',
  props.narrow ? 'section--narrow' : '',
  props.noPadding ? 'section--nopadding' : '',
  props.noBottomPadding ? 'section--nobottompadding' : '',
  props.flatHeader ? 'section--flat-header' : '',
  hasInverseColors.value ? 'has-inverse-colors' : ''
])
</script>

<style lang="scss">
.section {
  padding: _rem(64px) var(--page-margin);

  @include breakpoint(small down) {
    padding-top: _rem(32px);
    padding-bottom: _rem(32px);
  }

  &--fullwidth {
    padding-left: 0;
    padding-right: 0;
  }
  &--nopadding {
    padding: 0 !important;
  }
  &--nobottompadding {
    padding-bottom: 0 !important;
  }
  &--flat-header &__header {
    @include breakpoint(medium up) {
      display: flex;
      gap: var(--grid-double-gap);
      > * {
        flex: 1;
      }
    }
  }

  &--blue { background-color: var(--color-blue) }
  &--dark-blue { background-color: var(--color-dark-blue) }
  &--red { background-color: var(--color-crimson) }
  &--grey { background-color: var(--color-dark-8-bg) }
  &--gold { background-color: var(--color-gold) }

  &__content {
    max-width: var(--page-width);
    margin: 0 auto;
    position: relative;
  }
    &--fullwidth &__content {
      max-width: 100%;
    }
    &--narrow &__content {
      max-width: var(--page-width-narrow);
    }

  &__subtitle {
    max-width: _rem(860px);
  }
}
</style>