import { setupCalendar } from 'v-calendar'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(setupCalendar, {
    locales: {
      'cs': {
        firstDayOfWeek: 2,
        masks: {
          weekdays: 'WW'
        }
      },
      'en': {
        firstDayOfWeek: 1,
        masks: {
          weekdays: 'WW'
        }
      }
    }
  })
})